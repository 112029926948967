import React, { Component } from 'react';
import { FaCircleNotch, FaCheck, FaTimes, FaTrashAlt } from 'react-icons/fa';
import Dropzone from 'react-dropzone';
import Toggle from 'react-toggle';
import { API_ROOT } from '../../consts';
import './FileUpload.css';
import Modal from '../Modal/Modal';

const parallelFetch = async (datas, handler, parallel = 5) => {
  if (!Array.isArray(datas)) throw new Error(`Input data must be array!`);

  // handler must be either a function or an array of functions
  if (
    typeof handler !== 'function' &&
    !(
      Array.isArray(handler) &&
      handler.filter((func) => typeof func !== 'function').length &&
      handler.length === datas.length
    )
  )
    throw new Error(
      `handler must be either a function or an array of functions that match the datas length!`
    );

  const isSingleFunction = typeof handler === 'function';
  let count = 0;
  const results = new Array(datas.length);
  const fetchInSequence = async (threadIdx) => {
    if (count >= datas.length) return Promise.resolve();
    const fetchIdx = count;
    const fetchHandler = isSingleFunction ? handler : handler[fetchIdx];
    ++count;
    console.log(
      `${count}/${datas.length} fetched! Parallel threadId: ${threadIdx}`
    );
    try {
      results[fetchIdx] = await fetchHandler(datas[fetchIdx]);
    } catch (e) {
      results[fetchIdx] = e;
    }
    return fetchInSequence(threadIdx);
  };
  await Promise.all(
    new Array(parallel)
      .fill(1)
      .map((_, threadIdx) => fetchInSequence(threadIdx))
  );
  return results;
};

export default class FileUpload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      uploading: false,
      allDone: false,
      showModal: false,
      files: [],
      wallPlacement: false,
      placementOption: false,
    };
  }

  uploadFile = () => {
    const el = this.refs['fileUpload'];
    el.click();
  };

  deleteFile = (index) => {
    const { files } = this.state;
    files.splice(index, 1);
    this.setState({ files: files });
  };

  handleFileUpload = (ev, f) => {
    const { allowType, onFileUpload } = this.props;
    const { files } = this.state;
    let newFiles = f || ev.target.files;
    if (!Array.isArray(newFiles)) newFiles = Object.values(newFiles);
    if (!newFiles.length) return;
    for (let newFile of newFiles) {
      if (allowType && newFile.type !== allowType) {
        this.setState({ error: 'unsupported file format' });
        return;
      }
    }

    newFiles = newFiles.map((file) => ({
      file: file,
      s3Upload: 'no',
      threekitUpload: 'no',
      s3Url: '',
      errorMsg: '',
    }));

    newFiles = newFiles.filter((fileObj) => {
      const existFile = files.find(
        (obj) => obj.file.name === fileObj.file.name
      );
      if (existFile) return false;
      return true;
    });

    if (onFileUpload) {
      onFileUpload(files);
    }

    this.setState({
      files: files ? files.concat(newFiles) : newFiles,
      error: null,
    });
  };

  cancelUpload = () => {
    this.setState({ files: [] });
  };

  onUpload = async () => {
    const { files, uploading, wallPlacement, placementOption } = this.state;
    if (uploading) return;
    if (!files || !files.length) {
      window.confirm('Please choose a file to upload');
      return;
    }
    const parentUrl =
      window.location !== window.parent.location
        ? document.referrer
        : document.location.href;

    let hostenv = new URL(parentUrl).hostname;
    hostenv = hostenv === 'localhost' ? 'preview.threekit.com' : hostenv;
    this.setState({ uploading: true });

    await parallelFetch(files, async (fileObj) => {
      try {
        fileObj.s3Upload = 'uploading';
        this.setState({ files });
        fileObj.s3Upload = 'done';
        this.setState({ files });
      } catch (e) {
        fileObj.s3Upload = 'failed';
        fileObj.errorMsg = 'Failed to upload to s3';
        this.setState({ files });
      }
      try {
        fileObj.threekitUpload = 'uploading';
        const urlParams = new URLSearchParams(window.location.search);

        this.setState({ files });
        const form = new FormData();
        form.append(
          'mode',
          wallPlacement ? (placementOption ? 'ground' : 'wall') : ''
        );
        form.append('orgId', urlParams.get('orgId'));
        form.append('appid', urlParams.get('appid'));
        form.append('token', urlParams.get('token'));
        form.append('file', fileObj.file);
        const res = await fetch(`${API_ROOT}/importAR`, {
          method: 'POST',
          body: form,
          headers: { hostenv },
        });
        if (res.status === 200) fileObj.threekitUpload = 'done';
        else {
          fileObj.threekitUpload = 'failed';
          const json = await res.json();
          fileObj.errorMsg = json.error;
        }
        this.setState({ files });
      } catch (e) {
        fileObj.threekitUpload = 'failed';
        fileObj.errorMsg = e.toString();
        this.setState({ files });
      }
    });
    this.setState({ allDone: true });
  };

  onWallplacementCheck = () =>
    this.setState({ wallPlacement: !this.state.wallPlacement });

  onPlacementOptionCheck = () =>
    this.setState({ placementOption: !this.state.placementOption });

  render() {
    const {
      error,
      files,
      uploading,
      showModal,
      allDone,
      wallPlacement,
      placementOption,
    } = this.state;
    const { header, filenote, dropZone } = this.props;
    const hideFileText = files && !!files.length;
    return (
      <div className='container'>
        {header && <div className='header'>{header}</div>}
        <div className='toogle-container'>
          <div className='toogle-label'>Wall Placement:</div>
          <Toggle
            defaultChecked={wallPlacement}
            onChange={this.onWallplacementCheck}
          />
          <div className='toogle-label'>
            {wallPlacement
              ? 'Convert AR file to wall placement'
              : 'Use original AR file placement'}
          </div>
        </div>
        {wallPlacement && (
          <div className='toogle-container'>
            <div className='toogle-label'>Ground-Wall Rotation:</div>
            <Toggle
              defaultChecked={placementOption}
              onChange={this.onPlacementOptionCheck}
            />
            <div className='toogle-label'>
              {placementOption
                ? 'Apply to the asset that is originally horizontally aligned'
                : 'Apply to the asset that is originally vertically aligned'}
            </div>
          </div>
        )}
        <div>
          <div className='spreadshee'>
            {!uploading && (
              <div>
                <div style={{ paddingTop: '5%' }}>
                  <button className='choosefile' onClick={this.uploadFile}>
                    Choose file
                  </button>
                  <span className='filenote'>{filenote}</span>
                </div>
                <input
                  type='file'
                  ref='fileUpload'
                  onChange={this.handleFileUpload}
                  style={{ display: 'none' }}
                  multiple
                />
                {dropZone && (
                  <Dropzone
                    className='dropZone'
                    onDrop={(file) => this.handleFileUpload(null, file)}
                  >
                    <p className='dropTxt'>Or drop file here</p>
                  </Dropzone>
                )}
                {hideFileText && (
                  <div className='addedTitle' style={{ marginTop: 20 }}>
                    File added
                  </div>
                )}
                {error && <span className='errorTxt'>{error}</span>}
              </div>
            )}
            {uploading && !allDone && (
              <p>
                Uploading in progress, please do NOT refresh or close this page.
              </p>
            )}
            {files.length > 0 && (
              <table className='table'>
                <thead>
                  <tr>
                    <th>#</th>
                    <th style={{ textAlign: 'left' }}>File Name</th>
                    <th>{uploading && 'S3 Upload'}</th>
                    <th>{uploading && 'Threekit Upload'}</th>
                    <th style={{ textAlign: 'left' }}>
                      {uploading && 'Message'}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {files.map((obj, ind) => (
                    <tr key={ind}>
                      <th>{ind + 1}</th>
                      <th style={{ textAlign: 'left' }}>
                        {obj && obj.file.name}
                      </th>
                      <th>
                        {obj.s3Upload === 'uploading' && (
                          <FaCircleNotch
                            className='icon-spin'
                            title={obj.s3Upload}
                          />
                        )}
                        {obj.s3Upload === 'done' && (
                          <FaCheck title={obj.s3Upload} />
                        )}
                        {obj.s3Upload === 'failed' && (
                          <FaTimes title={obj.s3Upload} />
                        )}
                      </th>
                      <th>
                        {obj.threekitUpload === 'uploading' && (
                          <FaCircleNotch
                            className='icon-spin'
                            title={obj.threekitUpload}
                          />
                        )}
                        {obj.threekitUpload === 'done' && (
                          <FaCheck title={obj.threekitUpload} />
                        )}
                        {obj.threekitUpload === 'failed' && (
                          <FaTimes title={obj.threekitUpload} />
                        )}
                      </th>
                      <th style={{ textAlign: 'left' }}>
                        {!uploading && (
                          <FaTrashAlt
                            className='delete-icon'
                            title='delete'
                            onClick={() => this.deleteFile(ind)}
                          />
                        )}
                        {(obj.s3Upload === 'failed' ||
                          obj.threekitUpload === 'failed') &&
                          obj.errorMsg}
                        {obj.threekitUpload === 'done' && 'Successful'}
                      </th>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>
          {uploading && allDone && (
            <div style={{ width: '100%', padding: '3em', textAlign: 'left' }}>
              Please check the message of the failed uploads, there are some
              common issues,
              <li>
                Network issue, the failure happens on s3 upload stage always
                means a network issue.
              </li>
              <li>
                File structure issue, there is no sub folder with the same name
                as the zip file inside the zip.
              </li>
              <li>
                File structure issue, there is no glb and usdz files inside the
                zip.
              </li>
              <li>
                Missing file issue, there is no glb and usdz files inside the
                zip.
              </li>
              <p>
                For the network issue, please try again. For file issue, please
                check the zip file.
              </p>
              <p>
                If you can't solve the issue please contact threekit support.
              </p>
            </div>
          )}
          {!uploading && (
            <div className='action'>
              <button
                className='upload'
                disabled={files.length === 0}
                onClick={() => this.onUpload()}
              >
                Upload
              </button>
              <button className='cancel' onClick={this.cancelUpload}>
                Cancel
              </button>
            </div>
          )}
          {uploading && (
            <div className='action'>
              <button
                className='upload'
                disabled={!allDone}
                onClick={() => {
                  this.setState({
                    files: [],
                    allDone: false,
                    uploading: false,
                  });
                }}
              >
                OK
              </button>
            </div>
          )}
          <div
            className='tooltip'
            onClick={() => this.setState({ showModal: true })}
          >
            <img src='assets/question-circle-solid.svg' />
          </div>
          {showModal && (
            <Modal onClose={() => this.setState({ showModal: false })} />
          )}
        </div>
      </div>
    );
  }
}
