import React from 'react';
import FileUpload from './components/FileUpload/FileUpload';

import './App.css';

function App() {
  return (
    <div className="App">
      <h1>AR Asset Upload Helper</h1>
      <FileUpload
        filenote="zip format only"
        dropZone={true}
        allowType="application/zip"
      />
    </div>
  );
}

export default App;
