import React from 'react';
import './Modal.css';

const Modal = ({ onClose }) => (
  <div id="myModal" class="modal" onClick={onClose}>
    <div class="modal-content">
      <span class="close" onClick={onClose}>
        &times;
      </span>
      <div>
        This embed app will upload the AR assets to Threekit Platform. The asset
        files must be zipped and contain usdz and glb files, the name of the
        zipped files should be in this format: <strong>sku_productName</strong>.{' '}
      </div>
      <div>The basic workflow of this app is:</div>
      <ol>
        <li>Uploads the usdz and glb files to Threekit files system</li>
        <li>
          Creates catalog items with the name: sku_productName(The same as the
          AR asset files' name) and the sku in the metadata
        </li>
        <li>Creates configurations for each catalog item</li>
        <li>
          Creates layers for each catalog item which hosts the usdz and glb
          files
        </li>
      </ol>
      After this workflow, an AR assets will be successfully uploaded to
      Threekit platform.
    </div>
  </div>
);

export default Modal;
